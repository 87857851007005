import React , {useState , useEffect} from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// Styled Linear Progress Bar
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

// Circular Progress Bar
function FacebookCircularProgress(props) {

  


  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

function Home2() {

  const [isMobile, setIsMobile] = useState(false);
  console.log("page is opne")
  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);


  console.log("isMobile", isMobile);

  return (
    <>
      <div className="rounded-lg mt-[22px] border">
        <div className="mb-6 mt-[30px] ">
          <p className="md:text-[1.4rem] font-medium  ml-[15px]">Clikkle Plus </p>
          <p className='text-base leading-6 mt-[20px] ml-[15px]'>
            As a Clikkle Plus member, you get expanded storage, access to experts, and more.
          </p>
          <div  className='ml-[15px]' style={{  display: 'flex', flexDirection: 'row', alignItems: 'center' , width:'90%'
           }}>
            <div><img src='images/materilSymbol.svg' alt='Cloud Icon' className="w-12 h-12" /></div>
            <div style={{ flex: 1, margin: '0 10px' , width:"80%" , padding:'15px'
             }}>
                <p className='mt-[9px] mb-[9px]'>Account Storage</p>
              <BorderLinearProgress variant="determinate" value={30}  style={{height:'6px'}} />
              <p className='mt-[9px] mb-[9px]'>43.75 GB used of 100 GB</p>

            </div>
            <div>
                <img src='/images/share.svg' />
            </div>
          </div>
          <div  className="border-t border-t-1 border-gray-300">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Manage your Clikkle Plus Storage</p>
          </div>
        </div>
      </div>

      <div className="rounded-lg mt-[22px] border">
        <div className="mb-6 mt-[20px] ">
          {  !isMobile && <div className="flex justify-between">
            <div className="w-[60%]">
              <p className="md:text-[1.4rem] font-medium ml-[15px]">Privacy suggestions available</p>
              <p className='mt-[20px] ml-[15px]'>Take the privacy checkup and select the settings that are just right for you.</p>
            </div>
            <div className="w-[30%] flex items-center justify-end ml-[15px]">
              <img src='images/privicyReport.svg' alt='Privacy Report Icon' className="w-full h-[230px]" />
            </div>
          </div> }
         {  isMobile && 
         <div >
           <div className='flex ml-[15px] mr-[15px]'>  
            <p className="text-[1rem]   text-nowrap	">Privacy suggestions available</p>
            <img src="/images/certified.svg" alt='Privacy Report Icon' className="w-full  mt-[-6px] h-[3rem] p-2 " style={{marginRight:'10px'}} />
           
           </div>
           <p className='text-base mt-4 mb-6 ml-[15px]'>
           Take the privacy checkup and select the settings that are just right for you.
           </p>
       
           
         </div> }

          <div className="border-t border-t-1 border-gray-300">
          <p className='mt-[15px] ml-[15px]' style={{color:'#3767B1'}}>Review suggestions (4)</p>
          </div>
        </div>
        
      </div>
    </>
  );
}

export default Home2;
