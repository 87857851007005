import { Box, Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import style from '../../src/style/style.css'
import HomeLooking from "../components/HomeLooking";
import Home2 from "../components/Home2";

const Home = () => {
  return (
    <Box p={2}>
      <Grid   >
        {/* <Grid item xs>
          <Typography variant="h5" color="text.primary" className="text-2xl">
            Home
          </Typography>
        </Grid>

        <Grid item>
          <IconButton sx={{ display: { xs: "none", sm: "block" } }}>
            <InfoIcon />
          </IconButton>
        </Grid> */}
           <div className="flex items-center justify-between  md:w-full ">
           <Typography variant="h5" style={{color:'#A5A5A5'}} className="text-2xl ">
            Home
          </Typography>
          <div className="flex flex-row items-center justify-center gap-4">
           
            <InfoIcon />
          </div>
        </div>
      </Grid>

       <Grid container justifyContent="center" alignItems="center">
        <Box className="m-auto">
          <img
            className="border-3 border-gradient-to-b from-[#2A5BA7] to-[#06ADFF] h-36 w-36 rounded-full"
            src="/images/user.svg"
            alt="user"
          />
        </Box>
      </Grid>

      <Grid
  container
  justifyContent="center"
  alignItems="center"
  className="flex flex-col items-center mt-4"
  sx={{ flexDirection: "column", textAlign: "center" }}
>
  <Box className="m-auto">
    <Typography 
      className="text-3xl"
      sx={{ 
        fontSize: { xs: "1.5rem", sm: "2rem" },
        display: 'block' // Ensures it behaves as block element
      }}
    >
      <span className="welcome-text  ">Welcome,</span>
      <span className="name-text">Matthew Adebayo</span>
    </Typography>
  </Box>

  <Box
  className="m-auto mt-4"
  sx={{
    width: { xs: "60%", sm: "auto" },
    textAlign: { xs: "center", sm: "left" }, 
    mx: 'auto' 
  }}
>
  <Typography className="text-lg" sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
    Manage your info, privacy and security to make Clikkle work better
    for you.{" "}
    <span className="text-[#3767B1]">
      Learn more{" "}
      <img
        src="/images/learnmore.svg"
        className="h-3 w-3 inline "
        alt="learn more"
      />
    </span>
  </Typography>
</Box>

</Grid>



      <Grid container className="flex flex-row mt-4" spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className="flex flex-row w-full p-2.5 border-t rounded-t-lg mt-8 border">
            <div className="flex-4 p-2.5 w-full">
            <p className="md:text-[1.4rem] font-medium w-[50%] text-nowrap   md:text-wrap
            
            "
            >
  Privacy & 
  Personalization
</p>

              <p className="mt-4 text-[1rem] font-normal	 w-full">
                See the data in your Clikkle Account
                
                and choose what activity is saved to 
                personalize your Clikkle experience.
              </p>
            </div>
            <div className="flex-3 p-6 md:mt-[0px] mt-[-17px]">
              <div className="rounded-full   md:w-[150px] md:h-[150px] w-[60px] h-[60px] " style={{backgroundColor:'#2a2626'}}>
                <div>
                <img src="/images/Group.svg" alt="privacy"  className="md:p-[5px] p-[1px]" />

                </div>
              </div>
            </div>
          </div>
          <div className="border-t rounded-b-lg border border-solid ">
          <p className="text-[#3767B1] text-base font-medium p-[18px] leading-8">
              Manage your data & privacy
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="flex flex-row w-full p-2.5 border-t rounded-t-lg mt-8 border">
          <div className="flex-4 p-2.5 w-full">
          <p className=" md:text-[1.4rem] font-medium">You have security tips</p>
              <p className="mt-4 text-base font-normal	">
                Security tips are found in the
                <br />
                security checkup.
                <br />
              </p>
            </div>
            <div className="flex-3 p-6 md:mt-[0px] mt-[-17px]">
            <div className="rounded-full md:w-[150px] md:h-[150px] w-[60px] h-[60px]  bg-#2a2626" style={{backgroundColor:'#2a2626'}}>
            <img src="/images/security.svg" alt="privacy" className="md:p-[20px] p-[10px]" />
              </div>
            </div>
          </div>
          <div className="border-t rounded-b-lg border border-solid ">
            <p className="text-[#3767B1] text-base font-medium p-[18px] leading-8">
              Manage your data & privacy
            </p>
          </div>
        </Grid>
      </Grid>
      <Home2/> 
      <HomeLooking /> 
      
    </Box>
  );
};

export default Home;
