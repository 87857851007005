import { Box, Grid, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
const Storage = () => {
  return (
    <Box p={2}>
      <Grid container alignItems="center" width="100%">
        <Grid item xs>
          <p className="text-2xl font-normal text-primary">
            Storage
          </p>
        </Grid>

        <Grid item>
          <IconButton sx={{ display: { xs: "none", sm: "block" } }}>
            <InfoIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container className="mt-5 border border-solid border-gray-300 rounded-lg flex justify-between">
        <div className="pt-8 pb-8 pl-[15px]">
          <p className="text-2xl mb-5">Get Your Space Back</p>
          <p className="text-base mb-5">See ways to free up space in Clikkle Files, E-sign and Ads.</p>
          <p className="text-base mb-5 " style={{ color: '#3767B1' }}>Free up account storage</p>
        </div>
        <div>
          <img 
            className="pt-5 pr-12 pl-0 pb-5 h-[197px]" 
            src="/images/buccket.svg" 
            alt="img" 
          />
        </div>
      </Grid>
    <Grid className="mt-[50px] mb-[25px]">
        <h2 className="text-center text-[25px] mb-[10px]">Ways to use your Clikkle Account storage</h2> 
    <p className="text-center text-[17px]">Back up your phone, upload photos and more</p> 

    </Grid>
    <Grid container sx={{border:'1px solid'  , borderRadius:'5px'}}>
    <Accordion  sx={{width:'100%'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
         <img src="/images/backup.svg" className="w-[20px]" />  <span style={{padding:'8px'}}> Back-Up your device</span>
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion  sx={{width:'100%'}}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
        <img src="/images/dragon.svg" className="w-[20px]" />  <span style={{padding:'8px'}}> Import photos from Swiprr to Clikkle Files</span>  
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion  sx={{width:'100%'}}>
      <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
       <img src="/images/cloud.svg" className="w-[25px]" />   
       <span style={{padding:'8px'}} > Import photos from iCloud to Clikkle Files</span>  
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion>
    </Grid>

    </Box>
  );
};

export default Storage;
