import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import PaymentIcon from '@mui/icons-material/Payment';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import HttpsIcon from '@mui/icons-material/Https';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
const fileManager = [
    {
        name: 'Home',
        icon: <AccountCircleSharpIcon fontSize='small' />,
        to: '/',
    },
    {
        name: 'Personal Info',
        icon: <ContactEmergencyIcon fontSize='small' />,
        to: '/personal',
    },
    {
        name: 'Data & Privacy',
        icon: <ToggleOnIcon fontSize='small' />,
        to: '/404',
    },
    {
        name: 'Security',
        icon: <HttpsIcon fontSize='small' />,
        to: '/404',
    },
    {
        name: 'People & Sharing',
        icon: <PeopleIcon fontSize='small' />,
        to: '/404',
    },
    {
        name: 'Payments & Subscription',
        icon: <PaymentIcon fontSize='small' />,
        to: '/subscrpition',
    },
];

const sharedFile = [
    {
        name: 'Shared with me',
        icon: <PeopleIcon fontSize='small' />,
        to: '/shared-with-me',
    },
    {
        name: 'Shared by me',
        icon: <PersonIcon fontSize='small' />,
        to: '/shared-by-me',
    },
];

export { fileManager, sharedFile };
