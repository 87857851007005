import React , {useState ,useEffect } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import LanguageIcon from "@mui/icons-material/Language";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
function AddressPersnolInfo() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);

    checkIsMobile();

    window.addEventListener('resize', checkIsMobile);

    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  return (
    <Grid>
      <div className="rounded-lg mt-5 border">
        <p className="ml-2.5 mt-5 mb-1.5  md:text-[1.4rem] font-medium">Addresses</p>
        <p className="ml-2.5">
          Your residential and workplace locations are utilized to tailor your
          interactions across Clikkle services and to provide you with more
          pertinent advertisements.
        </p>
        <p className="ml-2.5 mt-2">
          Additionally, you have the option to include addresses in your Clikkle
          Profile, with the ability to control whether others can view them.
          <span className="text-[#3767B1]">
            Learn more{" "}
            <img
              src="/images/learnmore.svg"
              className="h-3 w-3 inline"
              alt="learn more"
            />
          </span>
        </p>

        <div className="flex justify-between items-center mt-5 mb-2.5 pt-3 pb-3 text-[0.9rem] ">
          <p className="ml-2.5 flex-1">Home</p>
          <p className="flex-1 text-center">Not Set</p>
          <div className="flex-1 flex justify-end">
            <ArrowForwardIosIcon
              className="mr-2.5 w-[10px]"
              style={{ fontSize: "15px" }}
            />
          </div>
        </div>

        <div className="flex justify-between items-center border-t mt-5 mb-2.5 pt-[20px] pb-3 text-[0.9rem] ">
          <p className="ml-2.5 flex-1">Work</p>
          <p className="flex-1 text-center">Not Set</p>
          <div className="flex-1 flex justify-end">
            <ArrowForwardIosIcon
              className="mr-2.5 w-[10px]"
              style={{ fontSize: "15px" }}
            />
          </div>
        </div>

        <div className="flex justify-between items-center border-t mt-5 mb-2.5 pt-[20px] pb-3 text-[0.9rem] ">
          <p className="ml-2.5 flex-1">Other Addresses</p>
          <p className="flex-1 text-center">None</p>
          <div className="flex-1 flex justify-end">
            <ArrowForwardIosIcon
              className="mr-2.5 w-[10px]"
              style={{ fontSize: "15px" }}
            />
          </div>
        </div>
      </div>
      <Grid container className="flex flex-row mt-4" spacing={2}>
        <Grid item xs={12} sm={6}>
          <div className="flex flex-row w-full p-2.5 border-t rounded-t-lg mt-8 border">
            <div className="flex-4 p-2.5 w-full ">
              <p className={`${!isMobile ? 'text-[1.4rem]' : 'text-xl'} font-medium w-[50%] text-nowrap`}>
                Your profiles
              </p>

              <p className="mt-4 text-[1rem] font-normal	mb-[3.3rem]">
                Observe the appearance of your <br />
                various profiles across Clikkle services
              </p>
            </div>
            <div className="flex-3 p-6">
              {/* <div className="rounded-full border w-[150px] h-[150px] " style={{backgroundColor:'#2a2626'}}>
                // {/* <img src="/images/Group.svg" alt="privacy" /> 
              </div> */}
            </div>
          </div>
          <div className="border-t rounded-b-lg border border-solid ">
            <p className="text-[#3767B1] text-base font-medium p-[18px] leading-8">
              See profiles
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="flex flex-row w-full p-2.5 border-t rounded-t-lg mt-8 border">
            <div className="flex-4 p-2.5 w-full">
              <p className=" md:text-[1.4rem] font-medium">Choose what others see</p>
              <p className="mt-4 text-base font-normal	">
                Choose which personal details you want to share with others
                while using your primary Clikkle Account profile across all
                Clikkle services.
              </p>
            </div>
           { !isMobile && <div className="flex-3 p-6">
              <div>
                <img
                  src="/images/readingNote.svg"
                  alt="privacy"
                  className="p-[20px]"
                />
              </div>
            </div>
}
          </div>
          <div className="border-t rounded-b-lg border border-solid ">
            <p className="text-[#3767B1] text-base font-medium p-[18px] leading-8">
              Manage your data & privacy
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        className="flex "
        style={{ marginTop: "60px", justifyContent: "space-between" }}
      >
        <div>
          <p className="md:text-[1.4rem] font-medium">
            Additional details and preferences for <br />
            Clikkle Services
          </p>
          <p className="mt-2">
            Methods for confirming your identity and preferences for web
            settings
          </p>
        </div>
        <div className={`${!isMobile ? 'mt-[-45px]' : 'mt-[0px]'}`}>
          <img src="/images/Todo.svg" style={{ width: "350px" }} />
        </div>
      </Grid>
      <Grid container className="flex flex-row mt-4" spacing={2}>
        <Grid item xs={12} sm={6}>
        <div className=" w-full  border-t rounded-t-lg mt-8 border" style={{borderRadius:'6px'}}>
        <div className="flex-4 p-2.5 w-full mt-2">
              <p className="text-[1.4rem] font-medium w-[50%] ">Password</p>

              <p className="mt-4 text-[1rem] font-normal	mb-10">
                A secure password helps protect your Clikkle Account
              </p>
            </div>
            <div
              className={`flex ${!isMobile ? 'mb-[9.9rem]' :'mb-[20px]'}`}
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex pl-[10px]" style={{ flexDirection: "column" }}>
                <p className="">***********</p>
                <p>Last changed March 15th, 2024</p>
              </div>
              <div>
                <ArrowForwardIosIcon
                  className="mr-2.5 w-[10px] "
                  style={{ fontSize: "15px" }}
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} spacing={2}>
          <div className=" w-full  border-t rounded-t-lg mt-8 border" style={{borderRadius:'6px'}}>
            <div className="flex-4 p-2.5 w-full mt-2">
              <p className="md:text-[1.4rem] font-medium">
                General preferences for the web
              </p>
              <p className="mt-4 text-base font-normal	">
                Manage settings for Clikkle services from the web.
              </p>
            </div>
            <div
              className="flex mt-5 mb-5 "
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex items-center pl-[10px]">
                <div className="flex items-center ">
                  <LanguageIcon
                    className="mr-2.5 w-[10px]"
                    style={{ fontSize: "25px" }}
                  />
                  <div className="flex flex-col">
                    <p className="text-[1rem]">Language</p>
                    <p className="text-[14px]">English (United States)</p>
                  </div>
                </div>
              </div>

              <div>
                <ArrowForwardIosIcon
                  className="mr-2.5 w-[10px] "
                  style={{ fontSize: "15px" }}
                />
              </div>
            </div>
            <div
              className="flex mt-5 mb-5 border-t   pt-[15px]"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex items-center pl-[10px]">
                <div className="flex items-center ">
                  <KeyboardIcon
                    className="mr-2.5 w-[10px]"
                    style={{ fontSize: "25px" }}
                  />
                  <div className="flex flex-col">
                    <p className="text-[1rem]">Language</p>
                    <p className="text-[14px]">English (United States)</p>
                  </div>
                </div>
              </div>

              <div>
                <ArrowForwardIosIcon
                  className="mr-2.5 w-[10px] "
                  style={{ fontSize: "15px" }}
                />
              </div>
            </div>
            <div
              className="flex mt-5 mb-5 border-t pt-[15px]"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex items-center pl-[10px]">
                <div className="flex items-center">
                  <AccessibilityIcon
                    className="mr-2.5 w-[10px]"
                    style={{ fontSize: "25px" }}
                  />
                  <div className="flex flex-col">
                    <p className="text-[1rem]">Language</p>
                    <p className="text-[14px]">English (United States)</p>
                  </div>
                </div>
              </div>

              <div>
                <ArrowForwardIosIcon
                  className="mr-2.5 w-[10px] "
                  style={{ fontSize: "15px" }}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddressPersnolInfo;
