import { Box, Grid, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import React, { useState , useEffect } from "react";
function ContactInfo() {
    const [profileImage, setProfileImage] = useState("/images/user.svg");
    const [isMobile, setIsMobile] = useState(false);

    const handleImageChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setProfileImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    useEffect(() => {
      const checkIsMobile = () => setIsMobile(window.innerWidth <= 768);
  
      checkIsMobile();
  
      window.addEventListener('resize', checkIsMobile);
  
      return () => window.removeEventListener('resize', checkIsMobile);
    }, []);
  
  
  return (
<>
<Grid>
          <div className="rounded-lg mt-5 border">
            <p className="ml-2.5 mt-5 mb-1.5  md:text-[1.4rem] font-medium">
              Contact Info
            </p>
        
            <div className="flex justify-between  mt-6 mb-2.5 pt-[20px] pb-3  text-[0.9rem]">
              <p className="ml-2.5">Email</p>
              <div>
              <p>matthewadebayo@clikkmail.com</p>
              <p>matthewadebayo@clikkmail.com</p>
              </div>
             

              <ArrowForwardIosIcon className="mr-2.5 w-[15px]" style={{width:"15px"}} />
            </div>
            <div className="flex justify-between border-t mt-3.75 mb-2.5 pt-[20px] pb-3  text-[0.9rem]">
              <p className="ml-2.5">Phone Number</p>
              <p>+234 904 945 3391</p>
              <ArrowForwardIosIcon className="mr-2.5 w-[15px]" style={{width:"15px"}} />
            </div>
            <div className=" border-t mt-3.75 mb-2.5 pt-3 pb-3  text-[0.9rem]">
              <p className="ml-2.5">More Options</p>
              <div className={`ml-2.5 mt-[1rem] ${isMobile ? 'w-[80%]' : 'w-[25%]'} `} style={{paddingTop:'8px' , paddingLeft:'16px' , paddingBottom:'8px' ,  border:'1px solid  ' , borderRadius:'13px'  , display:'flex'
    


              }}>
              <img src="/images/sms.svg"  className="h-[22px]" />
                  <p className=" ml-[5px] text-[16px]" >  Manage emails from Clikkle</p>
              </div>
            </div>
          </div>
        </Grid> 
        {/* <Grid>
          <div className="rounded-lg mt-5 border">
            <p className="ml-2.5 mt-5 mb-1.5 text-xl font-normal">
            Looking for something else?            </p>
        
       
          </div>
        </Grid>  */}







</> )
}

export default ContactInfo